import React from "react";

import goal1 from '../img/goal1.png'
import goal2 from '../img/goal2.png'
import goal3 from '../img/goal3.svg'

import { useTranslation } from "react-i18next";

function Goal () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1220px] mx-[20px] xl:mx-auto relative bg-[#1C1C25] mt-[100px] xl:mt-[200px]">
            <div className="flex flex-col xl:flex-row justify-between">
                <div>
                    <img src={goal3} alt="" className="absolute xl:left-[240px] xl:top-[-70px] scale-50 xl:scale-100 right-0 top-[-50px]"/>
                    <p className="lozung text-[48px] xl:text-[96px] text-white">{t('goal1')}</p>
                    <img src={goal1} alt="" className="absolute top-0 left-[-230px] hidden xl:block"/>
                    <img src={goal2} alt="" className="ml-[150px] hidden xl:block"/>
                </div>
                <div className="xl:max-w-[800px] mt-[20px]">
                    <p className="manrope text-[20px] xl:text-[32px] text-white"><span className="opacity-[0.5]">{t('goal2')}</span><span className="text-[#25FFE5] opacity-100">{t('goal3')}</span><span className="opacity-[0.5]"> {t('goal4')}</span></p>
                    <p className="mt-[60px] xl:mt-[80px] manrope text-[20px] xl:text-[40px] text-white">{t('goal5')}</p>
                    <p className="text-white opacity-[0.7] font-[300] roboto text-[16px] mt-[20px]">{t('goal6')}</p>
                </div>
                <img src={goal2} alt="" className="ml-auto w-[94.711px] h-[100.65px] xl:hidden"/>
            </div>
        </div>
    );
}

export default Goal;