import React from "react";

import girl from '../img/girl.png'
import claps from '../img/claps.png'

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

function WhatSets () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1920px] relative overflow-hidden">
             <div className="max-w-[1220px] mx-[20px] xl:mx-auto relative bg-[#1C1C25] mt-[40px] xl:mt-[200px]">
                <Fade duaration={1000}><img src={claps} alt="" className="absolute hidden xl:block top-[310px] left-[-50px]"/></Fade>
                <p className="xl:max-w-[910px] text-[40px] xl:text-[96px] lozung text-white">{t('whatsets1')}</p>
                <div className="flex mt-[70px] gap-[40px] xl:gap-[70px] xl:items-center">
                    <p className="text-[48px] lozung text-[#25FFE5]">01</p>
                    <div>
                        <p className="text-white font-[300] manrope text-[20px] xl:text-[32px]">
                            {t('whatsets2')}
                        </p>
                        <p className="text-white max-w-[525px] opacity-[0.7] roboto text-[14px] xl:text-[16px] mt-[15px]">
                            {t('whatsets3')}
                        </p>
                    </div>
                </div>
                <div className="h-[1px] bgline my-[20px]"></div>
                <div className="flex mt-[70px] gap-[40px] xl:gap-[70px] xl:items-center">
                    <p className="text-[40px] lozung text-[#25FFE5]">02</p>
                    <div>
                        <p className="text-white font-[300] manrope text-[20px] xl:text-[32px]">
                            {t('whatsets4')}
                        </p>
                        <p className="text-white max-w-[525px] opacity-[0.7] roboto text-[14px] xl:text-[16px] mt-[15px]">
                            {t('whatsets5')}   
                        </p>
                    </div>
                </div>
                <div className="h-[1px] bgline my-[20px]"></div>
                <div className="flex mt-[70px] gap-[40px] xl:gap-[70px] xl:items-center">
                    <p className="text-[40px] lozung text-[#25FFE5]">03</p>
                    <div>
                        <p className="text-white font-[300] manrope text-[20px] xl:text-[32px]">
                            {t('whatsets6')}
                        </p>
                        <p className="text-white max-w-[525px] opacity-[0.7] roboto text-[14px] xl:text-[16px] mt-[15px]">
                            {t('whatsets7')}
                        </p>
                    </div>
                </div>
                <div className="h-[1px] bgline my-[20px]"></div>
                <div className="flex mt-[70px] gap-[40px] xl:gap-[70px] xl:items-center">
                    <p className="text-[40px] lozung text-[#25FFE5]">04</p>
                    <div>
                        <p className="text-white font-[300] manrope text-[20px] xl:text-[32px]">
                            {t('whatsets8')}
                        </p>
                        <p className="text-white max-w-[525px] opacity-[0.7] roboto text-[14px] xl:text-[16px] mt-[15px]">
                            {t('whatsets9')}
                        </p>
                    </div>
                </div>
                <div className="h-[1px] bgline my-[20px]"></div>
                <div className="flex mt-[70px] gap-[40px] xl:gap-[70px] xl:items-center">
                    <p className="text-[40px] lozung text-[#25FFE5]">05</p>
                    <div>
                        <p className="text-white font-[300] manrope text-[20px] xl:text-[32px]">
                            {t('whatsets10')}
                        </p>
                        <p className="text-white max-w-[525px] opacity-[0.7] roboto text-[14px] xl:text-[16px] mt-[15px]">
                            {t('whatsets11')} 
                        </p>
                    </div>
                </div>
                <Fade right><img src={girl} className="sm:absolute sm:right-[-500px] md:right-[-300px] xl:right-[-100px] bottom-0 mt-[60px] xl:mt-0" alt="" /></Fade>
            </div>
        </div> 
    );
}

export default WhatSets;