import React from "react";

import Fade from 'react-reveal/Fade'

import { useTranslation } from "react-i18next";

import main from '../img/main.png'
import man from '../img/man.png'
import retrip from '../img/retrip.png'

function Main ( currentLang ) {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1920px] mx-auto relative bg-[#1C1C25] xl:h-[1213px] overflow-hidden">
            <div className="left-blur"></div>
            <div className="right-blur"></div>
            <img src={main} alt="" className="w-full h-[500px] sm:h-auto xl:h-[1048px] object-cover absolute"/>
            <div className="max-w-[1220px] mx-[20px] xl:mx-auto relative flex flex-col">
                <Fade right><img src={retrip} alt="" className="hidden sm:block absolute right-0 xl:right-[100px] bottom-0 xl:top-[120px]"/></Fade>
                <Fade duration={800}><p className="hidden xl:block text-[150px] text-[#25FFE5] lozung mx-auto pt-[200px]">CryptoLine</p></Fade>
                <div className="pt-[100px] flex flex-col xl:hidden">
                    <p className="text-[48px] sm:text-[60px] text-[#25FFE5] lozung leading-[1]">crypto</p>
                    <p className="text-[48px] sm:text-[55px] text-[#25FFE5] lozung ml-auto sm:ml-[300px] leading-[1]">line</p>
                </div>
                <Fade duration={1000}><img src={man} alt="" className="max-h-[600px] xl:max-h-max absolute top-[100px] xl:top-[70px] sm:right-0 xl:right-[195px]"/></Fade>
                <p className="sm:max-w-[400px] md:max-w-[600px] manrope font-[300] text-[16px] xl:text-[32px] text-white leading-[1.4] mt-[500px] sm:mt-0 xl:mt-[-10px]">{t('main1')}</p>
                <p className="sm:max-w-[400px] xl:max-w-[372px] text-white opacity-70 font-[300] text-[14px] xl:text-[16px] roboto mt-[20px] xl:mt-[40px]">{t('main2')}</p>
                <div className="flex mt-[40px] xl:gap-[27.5px]">
                    <div className="button absolute xl:relative top-[500px] xl:top-0 xl:right-0 sm:right-[200px]">
                        <p className={`text-[12px] xl:text-[20px] ${currentLang === 'en' ? 'text-[12px]' : 'text-[12px]'} text-white roboto uppercase`}>{t('main3')}</p>
                    </div>
                    <div className="button absolute xl:relative top-[500px] xl:top-0 right-0">
                        <p className={`text-[12px] xl:text-[20px] ${currentLang === 'en' ? 'text-[12px]' : 'text-[12px]'} text-white roboto uppercase`}>{t('main4')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between xl:mt-[140px]">
                    <div className="flex flex-col">
                        <Fade left><div className="flex mx-auto sm:mx-0">
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] mt-[10px] xl:mt-[20px]">{t('main5')}</p>
                            <div className="ml-[6px]">
                                <div className="flex">
                                    <p className="sonsie text-[#25FFE5] text-[40px] xl:text-[64px] uppercase leading-[0.8]">$</p>
                                    <p className="text-[#25FFE5] text-[40px] xl:text-[64px] uppercase lozung leading-[1]">6+</p>
                                </div>
                                <p className="text-[#25FFE5] text-[16px] xl:text-[24px] uppercase lozung leading-[0.5]">{t('main6')}</p>
                            </div>
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] mt-[32px] xl:mt-[55px] ml-[5px]">{t('main7')}</p>
                        </div></Fade>
                        <div className="sm:w-[500px] h-[1px] bgline my-[20px] xl:mt-[50px] xl:mb-[60px]"></div>
                        <Fade left><div className="flex flex-col mx-auto sm:mx-0">
                            <p className="text-[#25FFE5] text-[16px] xl:text-[24px] uppercase lozung">{t('main8')}</p>
                            <p className="text-[#25FFE5] text-[40px] xl:text-[64px] uppercase lozung leading-[1]">1000+</p>
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] ml-[20px] xl:ml-[50px] leading-[1]">{t('main9')}</p>
                        </div></Fade>
                        <div className="sm:w-[500px] h-[1px] bgline my-[20px] xl:hidden flex"></div>
                    </div>
                    <div className="flex flex-col">
                        <Fade right><div className="flex flex-col xl:items-end mx-auto sm:mx-0">
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] leading-[1.6]">{t('main10')}</p>
                            <p className="text-[#25FFE5] text-[40px] xl:text-[64px] uppercase lozung leading-[1]">5,000</p>
                            <p className="text-[#25FFE5] text-[16px] xl:text-[24px] uppercase lozung leading-[0.4] ml-[35px] xl:ml-0">{t('main11')}</p>
                        </div></Fade>
                        <div className="sm:w-[500px] h-[1px] bgline my-[20px] xl:my-[30px] xl:rotate-180"></div>
                        <Fade right><div className="flex flex-col xl:items-end mx-auto sm:mx-0">
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] mr-[75px]">{t('main12')}</p>
                            <p className="text-[#25FFE5] text-[40px] xl:text-[64px] uppercase lozung leading-[1]">500+</p>
                            <p className="text-[#25FFE5] text-[16px] xl:text-[24px] uppercase lozung leading-[1]">{t('main13')}</p>
                            <p className="text-white text-[14px] xl:text-[20px] manrope font-[500] ml-[113px]">55 GEOs</p>
                        </div></Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;