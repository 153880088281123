import React from "react";

import Fade from 'react-reveal/Fade'

import lypa from '../img/lypa.svg'
import btc from '../img/btc.png'
import rocket from '../img/rocket.png'
import check from '../img/check.png'

import { useTranslation } from "react-i18next";

function FullCircle () {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1920px] relative overflow-hidden pt-[100px] xl:pt-0">
            <div className="max-w-[1220px] mx-[20px] xl:mx-auto relative bg-[#1C1C25] mt-[200px] xl:mt-[490px] flex flex-col">
                <Fade left><img src={btc} alt="" className="absolute top-[-150px] xl:top-[-250px] left-[-150px] scale-[0.7] xl:scale-[1]"/></Fade>
                <Fade right><img src={rocket} alt="" className="absolute right-[-100px] sm:right-[-300px] xl::right-[-160px] top-[-100px] xl:top-[-270px] z-20 scale-[0.8] xl:scale-[1]"/></Fade>
                <div className="flex xl:mx-auto -ml-[200px] scale-[0.3] sm:scale-[0.5] xl:scale-[1] z-20 xl:z-0">
                    <p className="text-[150px] text-white lozung z-10">Crypt</p>
                    <img src={lypa} alt="" className="ml-[-60px] mr-[-39px]"/>
                    <p className="text-[150px] text-white lozung z-10">Line</p>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[-50px] xl:mt-[-30px]">
                    <p className="xl:max-w-[726px] text-[#25FFE5] text-[32px] xl:text-[64px] lozung leading-[1.2] z-10">{t('fullcircle1')}</p>
                    <div className="xl:max-w-[497px] mt-[40px] xl:mt-0">
                        <p className="text-[16px] xl:text-[24px] manrope text-white leading-[1.4] font-[300] z-10 relative">{t('fullcircle2')}</p>
                        <p className="mt-[20px] text-[#25FFE5] font-[600] text-[16px] xl:text-[24px] manrope relative z-10">{t('fullcircle3')}</p>
                    </div>
                </div>
                <div className="flex flex-col xl:flex-row justify-between mt-[40px] xl:mt-[80px]">
                    <div className="flex">
                        <img src={check} alt="" />
                        <p className="uppercase manrope text-[16px] xl:text-[24px] ml-[40px] text-white">{t('fullcircle4')}</p>
                    </div>
                    <div className="flex xl:w-[497px] mt-[20px] xl:mt-0">
                        <img src={check} alt="" />
                        <p className="uppercase manrope text-[16px] xl:text-[24px] ml-[40px] text-white">{t('fullcircle5')}</p>
                    </div>
                </div>
                <div className="flex xl:mt-[40px] flex-col xl:flex-row justify-between">
                    <div className="flex mt-[20px] xl:mt-0">
                        <img src={check} alt="" />
                        <p className="uppercase manrope text-[16px] xl:text-[24px] ml-[40px] text-white">{t('fullcircle6')}</p>
                    </div>
                    <div className="flex xl:w-[497px] mt-[20px] xl:mt-0">
                        <img src={check} alt="" />
                        <p className="uppercase manrope text-[16px] xl:text-[24px] ml-[40px] text-white">{t('fullcircle7')}</p>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default FullCircle;