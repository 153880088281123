import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import logo from "../img/logo.png";
import telegram from "../img/telegram.svg";
import instagram from "../img/instagram.svg";
import mail from "../img/mail.svg";

function Header({ currentLang, onChangeLang }) {
  const languages = [
    { value: "en", label: "EN" },
    { value: "ru", label: "RU" },
    { value: "ua", label: "UA" }
  ];

  const handleChangeLang = (selectedLang) => {
    onChangeLang(selectedLang.value);
  };

  return (
    <div>
      <div className="w-full fixed z-[999]">
        <div className="max-w-[1220px] xl:mx-auto header h-[60px] my-[10px] flex mx-[20px]">
          <div className="flex mx-[30px] items-center w-full">
            <img src={logo} alt="" className="w-[88.337px]" />
            <Dropdown
              className="ml-[40px]"
              options={languages}
              placeholder="EN"
              onChange={handleChangeLang}
              value={languages.find((lang) => lang.value === currentLang)}
            />
            <div className="ml-auto flex gap-[25px]">
              <Link>
                <img src={telegram} alt="" />
              </Link>
              <Link>
                <img src={instagram} alt="" />
              </Link>
              <Link>
                <img src={mail} alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
