import React, { useEffect, useState } from "react";

import i18n from "i18next";
import Cookies from "js-cookie";

import Header from "../components/Header";
import Main from "../components/Main";
import Goal from "../components/Goal";
import WhatSets from "../components/WhatSets";
import FullCircle from "../components/FullCircle";
import Ready from "../components/Ready";
import GetInTouch from "../components/GetInTouch";

function Home () {
 
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    const storedLang = Cookies.get("i18next");
    const [currentLang, setCurrentLang] = useState(storedLang || 'en');

    useEffect(() => {
        if (storedLang && storedLang !== currentLang) {
        setCurrentLang(storedLang);
        i18n.changeLanguage(storedLang);
        }
    }, [storedLang, currentLang]);

    const handleChangeLang = (lang) => {
        setCurrentLang(lang);
        i18n.changeLanguage(lang);
        Cookies.set("i18next", lang);
    };
    return(
        <div className="bg-[#1C1C25]">
            <Header currentLang={currentLang} onChangeLang={handleChangeLang}/>
            <Main currentLang={currentLang}/>
            <Goal/>
            <WhatSets/>
            <FullCircle/>
            <Ready currentLang={currentLang}/>
            <GetInTouch currentLang={currentLang}/>
        </div>
    );
}

export default Home;