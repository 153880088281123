import React from "react";

import footer from '../img/footer.png'
import footermob from '../img/footermob.png'

import { useTranslation } from "react-i18next";

function GetInTouch ({ currentLang }) {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1220px] mx-[20px] xl:mx-auto flex flex-col relative bg-[#1C1C25] mt-[185px] xl:mt-[360px]">
            <p className={`text-[40px] ${currentLang === 'en' ? 'xl:text-[96px]' : 'xl:text-[60px]'} lozung text-white z-10`}>{t('getintouch1')}</p>
            <p className="max-w-[703px] manrope text-[24px] text-white leading-[1.4] mt-[10px] font-[300] z-10">{t('getintouch2')}</p>
            <div className="relative max-w-[726px]">
                <p className="text-[32px] xl:text-[64px] text-[#7848FF] max-w-[726px] lozung mt-[60px] z-10 relative">{t('getintouch3')}</p>
                <p className="max-w-[421px] opacity-[0.7] text-white text-[14px] xl:text-[16px] font-[300] z-10">{t('getintouch4')}</p>
            </div>
            <div className="button flex mr-auto mt-[40px]">
                <p className="text-[14px] xl:text-[20px] text-white roboto uppercase">{t('getintouch5')}</p>
            </div>
            <img src={footermob} alt="" className="xl:hidden block"/>
            <div className="flex flex-col xl:flex-row mt-[35px] mb-[10px] justify-between items-center xl:items-start">
                <p className="text-white opacity-[0.7] roboto text-[16px]">CryptoLine © All rights reserved.</p>
                <div className="flex flex-col xl:flex-row z-10 xl:gap-[50px] items-center xl:items-start">
                    <a href="/Privacycryptoline.pdf"><p className="roboto text-[16px] font-[600] cursor-pointer text-white xl:text-black">{t('getintouch6')}</p></a>
                    <a href="/Termscryptoline.pdf"><p className="roboto text-[16px] font-[600] cursor-pointer text-white xl:text-black">{t('getintouch7')}</p></a>     
                </div> 
            </div>
            <img src={footer} alt="" className="hidden xl:block absolute bottom-0 xl:right-[-30px] 2xl:right-[-150px] blur-sm 2xl:blur-0"/>
            
        </div>
    );
}

export default GetInTouch;