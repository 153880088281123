import React from "react";

import coin from '../img/coin.png'

import { useTranslation } from "react-i18next";

function Ready ({ currentLang }) {

    const { t } = useTranslation();

    return(
        <div className="max-w-[1220px] mx-[20px] xl:mx-auto flex flex-col relative bg-[#1C1C25] mt-[100px] xl:mt-[200px]">
            <p className={`text-[40px] ${currentLang === 'ru' ? 'xl:text-[60px]' : 'xl:text-[96px]'} lozung text-white`}>{t('ready1')}<span className="text-[#25FFE5]">{t('ready2')}</span> {t('ready3')}</p>
            <img src={coin} alt="" className="absolute hidden xl:block top-[140px] right-[70px]"/>
            <p className={`text-[32px] xl:text-[64px] ${currentLang === 'ru' ? 'xl:mt-[100px]' : 'mt-0'} text-[#7848FF] lozung xl:ml-auto`}>{t('ready4')}</p>
            <div className="flex flex-col xl:flex-row mt-[20px] xl:mt-[60px]">
                <div className="xl:w-[1px] h-[1px] w-full xl:h-[392px] bg-[#7848FF]"></div>
                <div className="w-full h-[392px] bg-[#7848FF1A]">
                    <p className="text-[#7848FF] lozung text-[40px] m-[40px]">01</p>
                    <p className={`text-[24px] xl:text-[32px] manrope text-white font-[300] mx-[40px]`}>{t('ready5')}</p>
                    <p className="mt-[16px] text-[#FFFFFFB2] font-[300] roboto mx-[40px]">{t('ready6')}</p>
                </div>
                <div className="xl:w-[1px] h-[1px] w-full xl:h-[392px] bg-[#7848FF]"></div>
                <div className="w-full h-[392px] bg-[#7848FF1A]">
                    <p className="text-[#7848FF] lozung text-[40px] m-[40px]">02</p>
                    <p className={`text-[24px] xl:text-[32px] manrope text-white font-[300] mx-[40px]`}>{t('ready7')}</p>
                    <p className="mt-[16px] text-[#FFFFFFB2] font-[300] roboto mx-[40px]">{t('ready8')}</p>
                </div>
                <div className="xl:w-[1px] h-[1px] w-full xl:h-[392px] bg-[#7848FF]"></div>
                <div className="w-full h-[392px] bg-[#7848FF1A]">
                    <p className="text-[#7848FF] lozung text-[40px] m-[40px]">03</p>
                    <p className={`text-[24px] xl:text-[32px] manrope text-white font-[300] mx-[40px]`}>{t('ready9')}</p>
                    <p className="mt-[16px] text-[#FFFFFFB2] font-[300] roboto mx-[40px]">{t('ready10')}</p>
                </div>
            </div>
        </div>
    );
}

export default Ready;